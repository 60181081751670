import React, { useCallback, useMemo, useState } from "react";

import {
  FMEARiskList as FMEARiskListType,
  isRiskPolicy,
} from "@design-controls/types";
import {
  createQColumnHelper,
  DataProvider,
  Filtering,
  Pagination,
  QCenter,
  QDataTable,
  QDeleteConfirmationModal,
  QIcon,
  QText,
  TableMenuItem,
} from "@qualio/ui-components";
import { Outlet } from "react-router";

import { useDeleteRisk } from "../../../hooks/designElement/useDeleteRisk";
import { useFMEARiskList } from "../../../hooks/useFMEARiskList";
import { usePolicyGroupResourceQuickSearch } from "../../../hooks/qri/usePolicyGroupResourceQuickSearch";
import { CategoryFilter } from "../../filter/CategoryFilter";

const columnHelper = createQColumnHelper<FMEARiskListType>();

export const FMEARiskList = () => {
  const { isLoading, data } = useFMEARiskList();
  const [itemToDelete, setItemToDelete] = useState<FMEARiskListType | null>(
    null,
  );
  const { isLoading: isDeleting, mutate: deleteElement } = useDeleteRisk();

  const { isLoading: categoryIsLoading, data: categories } =
    usePolicyGroupResourceQuickSearch(isRiskPolicy);

  const filterDefinitions = useMemo(() => {
    return {
      category: {
        label: "Category",
        schema: Filtering.schemas.StringSchema().array().nullish(),
        activeRender: (v: any) => {
          const item = categories?.find((c) => c.identifiers.qri === v);
          if (!item) {
            return;
          }

          return {
            label: "Category",
            value: item.displayLabel,
          };
        },
      },
    };
  }, [categories]);

  const filterOption = useCallback(
    (
      item: Readonly<FMEARiskListType>,
      searchTerm: string | undefined,
      filters: Filtering.ResolvedFilters<typeof filterDefinitions> | undefined,
    ) => {
      if (
        searchTerm &&
        !item.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
      ) {
        return false;
      }

      if (filters && Object.values(filters).some((field) => field.value)) {
        const { category } = filters;

        return !category?.value || category.value.includes(item.category?.qri);
      }

      return true;
    },
    [],
  ) as DataProvider.FilterOptionFn<FMEARiskListType>;

  const columns = useMemo(() => {
    return [
      columnHelper.code("code", { header: "ID", maxWidth: "100px" }),
      columnHelper.textLink("title", (record) => record.id, {
        header: "Risk name",
        weight: 1,
      }),
      columnHelper.text((record) => record.category?.label, {
        header: "Category",
      }),
      columnHelper.date((record) => new Date(record.updated), {
        header: "Last modified",
        maxWidth: "150px",
      }),
      {
        header: "Issue",
        meta: {
          width: "70px",
        },
        cell: ({
          row: { original: row },
        }: {
          row: { original: FMEARiskListType };
        }) => {
          const missing = Object.entries(row.policyIssues).filter(
            ([, value]) => !!value,
          );

          if (!missing.length) {
            return "";
          }

          return (
            <QCenter>
              <QIcon
                iconName="FillExclamationTriangleFill"
                color="orange.500"
              />
            </QCenter>
          );
        },
      },
      columnHelper.menu({
        items: (
          <>
            <TableMenuItem onClick={console.log} data-cy="edit">
              Edit
            </TableMenuItem>
            <TableMenuItem
              onClick={(item: FMEARiskListType) => setItemToDelete(item)}
              color="red.500"
              data-cy="delete"
            >
              Delete
            </TableMenuItem>
          </>
        ),
        hide: (record) => record.source !== "product-development",
      }),
    ];
  }, []);

  return (
    <>
      <Pagination.Auto pageSizeKey="size" pageIndexKey="page" clientSide>
        <Filtering.FilterProvider
          definitions={filterDefinitions}
          searchTermKey="search"
        >
          <DataProvider.Fixed
            data={data ?? []}
            isLoading={isLoading}
            filterOption={filterOption}
          >
            <QDataTable
              columns={columns}
              data-cy="fmea-risk-list"
              getRowId={(row) => row.id}
            >
              <Filtering.FormContent>
                <CategoryFilter
                  categories={categories}
                  isLoading={categoryIsLoading}
                />
              </Filtering.FormContent>
            </QDataTable>
          </DataProvider.Fixed>
        </Filtering.FilterProvider>
      </Pagination.Auto>
      <Outlet />
      {itemToDelete && (
        <QDeleteConfirmationModal
          title="Delete risk"
          onConfirm={() => {
            itemToDelete &&
              deleteElement(itemToDelete as any, {
                onSettled: () => setItemToDelete(null),
              });
          }}
          inProgress={isDeleting}
          onClose={() => setItemToDelete(null)}
          message={
            <QText fontSize="sm">
              Are you sure you want to delete {itemToDelete.codeTitle}? You
              cannot undo this action.
            </QText>
          }
        />
      )}
    </>
  );
};
