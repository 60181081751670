import { z } from "zod";
import { PolicyIssue } from "./openIssue";
import { AttachmentWithId } from "./attachment";

export enum ChangeControlStatusEnum {
  NOT_IN_REVIEW = "unstaged",
  IN_PROGRESS = "staged",
  APPROVED = "approved",
  NOT_APPROVED = "not_approved",
}

export const ChangeControlStatus = z.nativeEnum(ChangeControlStatusEnum);
export type ChangeControlStatus = z.infer<typeof ChangeControlStatus>;

export const BaseDesignElement = z.object({
  id: z.string(),
  title: z.string().min(1),
  code: z.string(),
  itemStatus: z.string(),
  changeControlStatus: ChangeControlStatus,
  created: z.string(),
  updated: z.string(),
  source: z.string(),
  version: z.string(),
  policyIssues: z.record(PolicyIssue, z.boolean()),
});
export type BaseDesignElement = z.infer<typeof BaseDesignElement>;

const LinkedElement = z.object({
  id: z.string(),
  title: z.string(),
  code: z.string(),
  itemStatus: z.string(),
  changeControlStatus: z.string(),
  created: z.string(),
  updated: z.string(),
  source: z.string(),
  type: z.string(),
  qri: z.string(),
});
export type LinkedElement = z.infer<typeof LinkedElement>;

export const Requirement = BaseDesignElement.extend({
  description: z.string().nullish(),
  category: z
    .object({
      qri: z.string(),
      label: z.string(),
    })
    .optional(),
  component: z
    .object({
      qri: z.string(),
      label: z.string(),
    })
    .optional(),
  children: LinkedElement.array(),
  parent: LinkedElement.array(),
  attachments: AttachmentWithId.array(),
  version: z.string(),
  sourcedItems: LinkedElement.array(),
  mitigates: LinkedElement.array(),
});

export const Level1RequirementElement = Requirement.extend({
  type: z.literal("req1"),
});
type Level1RequirementElement = z.infer<typeof Level1RequirementElement>;

export const Level2RequirementElement = Requirement.extend({
  type: z.literal("req2"),
});
type Level2RequirementElement = z.infer<typeof Level2RequirementElement>;

export const Level3RequirementElement = Requirement.extend({
  type: z.literal("req3"),
});
type Level3RequirementElement = z.infer<typeof Level3RequirementElement>;

export const Level4RequirementElement = Requirement.extend({
  type: z.literal("req4"),
});
type Level4RequirementElement = z.infer<typeof Level4RequirementElement>;

export const RequirementDesignElement = z.discriminatedUnion("type", [
  Level1RequirementElement,
  Level2RequirementElement,
  Level3RequirementElement,
  Level4RequirementElement,
]);

export type RequirementDesignElement =
  | Level1RequirementElement
  | Level2RequirementElement
  | Level3RequirementElement
  | Level4RequirementElement;

export const TestResult = z.object({
  result: z.string(),
  executedBy: z.string(),
  date: z.string(),
  notes: z.string().nullish(),
  source: z.string(),
  attachments: AttachmentWithId.array(),
});
export type TestResult = z.infer<typeof TestResult>;

export const Level1TestCaseElement = Requirement.extend({
  type: z.literal("testCase1"),
  category: z
    .object({
      qri: z.string(),
      label: z.string(),
    })
    .optional(),
  testResult: TestResult.optional(),
});
type Level1TestCaseElement = z.infer<typeof Level1TestCaseElement>;

export const Level2TestCaseElement = Requirement.extend({
  type: z.literal("testCase2"),
  category: z
    .object({
      qri: z.string(),
      label: z.string(),
    })
    .optional(),
  testResult: TestResult.optional(),
});
type Level2TestCaseElement = z.infer<typeof Level2TestCaseElement>;

export const Level3TestCaseElement = Requirement.extend({
  type: z.literal("testCase3"),
  category: z
    .object({
      qri: z.string(),
      label: z.string(),
    })
    .optional(),
  testResult: TestResult.optional(),
});
type Level3TestCaseElement = z.infer<typeof Level3TestCaseElement>;

export type TestCaseDesignElement =
  | Level1TestCaseElement
  | Level2TestCaseElement
  | Level3TestCaseElement;

export const Risk = BaseDesignElement.extend({
  type: z.literal("risk"),
  subType: z.string().optional(),
  sourceItems: LinkedElement.array(),
  mitigationItems: LinkedElement.array(),
  category: z
    .object({
      qri: z.string(),
      label: z.string(),
    })
    .optional(),
}).passthrough();
export type Risk = z.infer<typeof Risk>;

export const ISOMitigation = z.object({
  probability: z.string(),
  severity: z.string(),
  riskLevel: z.string(),
});
export type ISOMitigation = z.infer<typeof ISOMitigation>;

export const ISORiskDesignElement = Risk.extend({
  subType: z.literal("iso").optional(),
  harm: z.string(),
  foreseeableUseMisuse: z.string(),
  postMitigation: ISOMitigation.optional(),
  hazardSituation: z.string(),
  riskControl: z.object({
    mitigation: z.string().optional(),
  }),
  hazard: z.string(),
  preMitigation: ISOMitigation,
});
export type ISORiskDesignElement = z.infer<typeof ISORiskDesignElement>;

export const FMEAMitigation = z.object({
  severity: z.string(),
  occurrence: z.string(),
  detectability: z.string(),
});
export type FMEAMitigation = z.infer<typeof FMEAMitigation>;

export const FMEARiskDesignElement = Risk.extend({
  subType: z.literal("fmea").optional(),
  failureCauses: z.string(),
  failureModeEffects: z.string(),
  failureMode: z.string(),
  evaluationMethod: z.string(),
  postMitigation: FMEAMitigation.optional(),
  riskControl: z.object({
    mitigation: z.string().optional(),
  }),
  preMitigation: FMEAMitigation,
});
export type FMEARiskDesignElement = z.infer<typeof FMEARiskDesignElement>;

// design element by id
export const DesignElement = z.discriminatedUnion("type", [
  Level1RequirementElement,
  Level2RequirementElement,
  Level3RequirementElement,
  Level4RequirementElement,
  Level1TestCaseElement,
  Level2TestCaseElement,
  Level3TestCaseElement,
  Risk,
]);

export type DesignElement = z.infer<typeof DesignElement>;

export const isDesignElementRequirement = (
  item: DesignElement,
): item is RequirementDesignElement => {
  return item.type.startsWith("req");
};

export const isDesignElementTestCase = (
  item: DesignElement,
): item is TestCaseDesignElement => {
  return item.type.startsWith("testCase");
};

export const isDesignElementRisk = (item: DesignElement): item is Risk => {
  return item.type === "risk";
};

export const isDesignElementISORisk = (
  item: DesignElement,
): item is ISORiskDesignElement => {
  return isDesignElementRisk(item) && (!item.subType || item.subType === "iso");
};

export const isDesignElementFMEARisk = (
  item: DesignElement,
): item is FMEARiskDesignElement => {
  return isDesignElementRisk(item) && item.subType === "fmea";
};
