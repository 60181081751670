import React, { useMemo } from "react";
import { useDeleteElementDrawer } from "../../hooks/useDesignElementDrawer";
import {
  QBodyLayout,
  QBox,
  QButton,
  QButtonGroup,
  QDateMetadataItem,
  QHeader,
  QHeading,
  QHorizontalMetadata,
  QNotFound,
  QOpenPropertiesPanelButton,
  QPageLoader,
  QPropertiesPanel,
  QStack,
  QText,
  QTitle,
  QTogglePropertiesPanelButton,
  QVersionMetadata,
} from "@qualio/ui-components";
import { isDesignElementRequirement } from "@design-controls/types";
import { sanitize } from "dompurify";
import { DesignReviewOverviewTable } from "../../components/DesignReviewOverviewTable/DesignReviewOverviewTable";
import { RequirementTable } from "../../components/OverviewTable/RequirementTable";
import { TestCaseTable } from "../../components/OverviewTable/TestCaseTable";
import { RisksTable } from "../../components/OverviewTable/RisksTable";
import { ItemStatusTag } from "../../components/ItemStatusTag/ItemStatusTag";
import { ReviewStatusTag } from "../../components/ReviewStatusTag/ReviewStatusTag";
import { AttachmentTable } from "../../components/OverviewTable/AttachmentTable";
import { isSoureEditableAndDeletable } from "../../domain/editableSource";

export const RequirementOverview: React.FC = () => {
  const { isLoading, designElement, designElementHistory } =
    useDeleteElementDrawer();

  const requirements = useMemo(() => {
    if (!designElement) {
      return [];
    }

    return [
      ...((designElement as any).children ?? []),
      ...((designElement as any).parent ?? []),
    ].filter((element) => element.type.startsWith("req"));
  }, [designElement]);

  const testCases = useMemo(() => {
    if (!designElement) {
      return [];
    }

    return [...((designElement as any).children ?? [])].filter((element) =>
      element.type.startsWith("testCase"),
    );
  }, [designElement]);

  const risks = useMemo(() => {
    if (!designElement) {
      return [];
    }

    return [
      ...(designElement as any).sourcedItems,
      ...((designElement as any).mitigates ?? []),
    ];
  }, [designElement]);

  if (isLoading) {
    return <QPageLoader />;
  }

  if (!designElement || !isDesignElementRequirement(designElement)) {
    return <QNotFound />;
  }

  return (
    <QBodyLayout.Default>
      <QHeader>
        <QTitle>
          <QText as="span" color="gray.500">
            {designElement?.code}
          </QText>{" "}
          {designElement?.title}
        </QTitle>
        <QHorizontalMetadata>
          <ItemStatusTag itemStatus={designElement.itemStatus} />
          <QVersionMetadata value={designElement?.version} />
          <QDateMetadataItem
            label="Created"
            value={new Date(designElement?.created ?? 0)}
          />
          <QDateMetadataItem
            label="Last modified"
            value={new Date(designElement?.updated ?? 0)}
          />
        </QHorizontalMetadata>
        <QButtonGroup>
          {isSoureEditableAndDeletable(designElement.source) && (
            <QButton leftIcon="Edit3" variant="outline" data-cy="edit">
              Edit
            </QButton>
          )}
          <QOpenPropertiesPanelButton />
        </QButtonGroup>
      </QHeader>
      <QStack gap="24px" data-cy="requirement-overview">
        <QStack gap="8px">
          <QText fontSize="sm" fontWeight={600} color="gray.900">
            Description
          </QText>
          {designElement.description && (
            <QText
              fontSize={14}
              style={{ wordBreak: "break-word" }}
              dangerouslySetInnerHTML={{
                __html: sanitize(designElement.description),
              }}
            />
          )}
        </QStack>

        {designElementHistory && designElementHistory?.length > 0 && (
          <DesignReviewOverviewTable changeHistory={designElementHistory} />
        )}

        <QStack gap="8px">
          <QHeading fontSize="md" fontWeight={600}>
            Related design elements
          </QHeading>
          <QText fontSize="sm">
            Connected requirements, test cases, and risks that establish
            traceability for this requirement.
          </QText>
        </QStack>

        <RequirementTable requirements={requirements} />
        <RisksTable risks={risks} />
        <TestCaseTable testCases={testCases} />

        {designElement.attachments.length > 0 && (
          <>
            <QStack gap="8px">
              <QHeading fontSize="md" fontWeight={600}>
                Attachments
              </QHeading>
              <QText fontSize="sm">
                Supporting documents and files associated with this requirement.
              </QText>
            </QStack>
            <AttachmentTable attachments={designElement.attachments} />
          </>
        )}

        <QPropertiesPanel openOnMount>
          <QStack spacing="8px" w="300px">
            <QStack direction="row" justifyContent="space-between">
              <QTitle>Properties</QTitle>
              <QTogglePropertiesPanelButton />
            </QStack>
            <QStack>
              <QText fontSize="sm" fontWeight={600}>
                Component
              </QText>
              <QText fontSize="sm" data-cy="component">
                {designElement.component?.label}
              </QText>
            </QStack>
            <QStack>
              <QText fontSize="sm" fontWeight={600}>
                Category
              </QText>
              <QText fontSize="sm" data-cy="category">
                {designElement.category?.label}
              </QText>
            </QStack>
            <QStack>
              <QText fontSize="sm" fontWeight={600}>
                Review status
              </QText>
              <QBox>
                <ReviewStatusTag status={designElement.changeControlStatus} />
              </QBox>
            </QStack>
          </QStack>
        </QPropertiesPanel>
      </QStack>
    </QBodyLayout.Default>
  );
};
