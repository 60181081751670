import { AttachmentWithId } from "@design-controls/types";
import { QDivider, QIconButton, QStack, QText } from "@qualio/ui-components";
import React, { useMemo } from "react";

type Props = {
  attachments: AttachmentWithId[];
};

const AttachmentItem: React.FC<AttachmentWithId> = ({ filename, id }) => {
  return (
    <QStack alignItems="center" direction="row" justify="space-between">
      <QText fontSize="sm" fontWeight="semibold">
        {filename}
      </QText>
      <QIconButton
        iconName="Download"
        aria-label="Download file"
        data-cy={`attachment-download-${id}`}
      />
    </QStack>
  );
};

export const AttachmentTable: React.FC<Props> = ({ attachments }) => {
  const joinedComponents = useMemo(() => {
    return attachments.reduce(
      (
        acc: readonly React.ReactElement[],
        current: AttachmentWithId,
        index: number,
      ) =>
        index === 0
          ? [<AttachmentItem {...current} />]
          : [...acc, <QDivider />, <AttachmentItem {...current} />],
      [],
    );
  }, [attachments]);

  return (
    <QStack
      border="1px"
      borderRadius="4px"
      padding="15px"
      borderColor="gray.200"
      data-cy="attachment"
    >
      {joinedComponents}
    </QStack>
  );
};
