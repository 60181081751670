import {
  BaseDesignElement,
  DesignElement,
  ElementList,
  QualioUser,
  ReviewList,
} from "@design-controls/types";
import axios from "axios";

const api = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

export const designControlsApi = {
  async query(
    company: number,
    product: string,
    type?: string,
  ): Promise<Array<ElementList>> {
    const elementTypeQuery = type ? `?type=${type}` : "";

    const response = await api.get<{ data: Array<ElementList> }>(
      `/company/${company}/product-development/product/${product}/openIssue${elementTypeQuery}`,
    );

    return ElementList.array().parse(response.data.data);
  },
  async getById(
    company: number,
    product: string,
    qualityItemId: string,
    qualityItemVersion?: string,
  ) {
    let url = `/company/${company}/product-development/product/${product}/qualityItem/${qualityItemId}`;
    if (qualityItemVersion !== undefined) {
      url = `${url}/version/${qualityItemVersion}`;
    }

    const response = await api.get<{ data: Array<DesignElement> }>(url);
    return DesignElement.parse(response.data.data);
  },
  async retrieveReviews(company: number, product: string) {
    const response = await api.get<{ data: Array<ReviewList> }>(
      `/company/${company}/product-development/product/${product}/change-control`,
    );

    return ReviewList.array().parse(response.data.data);
  },
  async retrieveUsers(company: number) {
    const response = await api.get<{ data: Array<QualioUser> }>(
      `/company/${company}/product-development/users`,
    );

    return QualioUser.array().parse(response.data.data);
  },
  async createDesignElement(
    company: number,
    product: string,
    body: Partial<DesignElement>,
  ): Promise<Partial<BaseDesignElement>> {
    const response = await api.post<{ data: DesignElement }>(
      `/company/${company}/product-development/product/${product}/qualityItem`,
      { data: { ...body, statusLabel: "N/A" } }, // remove statusLabel
    );

    return BaseDesignElement.partial().parse(response.data.data);
  },
} as const;
