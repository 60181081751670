import React from "react";

import { QFormControl, QSelect } from "@qualio/ui-components";
import { Controller } from "react-hook-form";
import { ResultValueToLabel } from "../../../constants";

export const TestResult: React.FC = () => {
  return (
    <Controller
      name="testResult"
      render={({ field: { onChange, value } }) => (
        <QFormControl label="Test type">
          <QSelect
            value={value}
            onChange={(selectedItem) =>
              onChange(selectedItem ? selectedItem.value : null)
            }
            options={Object.entries(ResultValueToLabel).map(
              ([value, label]) => ({
                value,
                label,
              }),
            )}
          />
        </QFormControl>
      )}
    />
  );
};
