import React, { useCallback, useMemo, useState } from "react";

import { isTestCasePolicy, TestCaseList } from "@design-controls/types";
import {
  createQColumnHelper,
  DataProvider,
  Filtering,
  QDataTable,
  TableMenuItem,
  QDeleteConfirmationModal,
  QText,
  Pagination,
  QTag,
  QCenter,
  QIcon,
} from "@qualio/ui-components";
import { Outlet } from "react-router";

import { useDeleteTestCase } from "../../../hooks/designElement/useDeleteTestCase";
import { useTestCaseList } from "../../../hooks/useTestCaseList";
import { TestResultElement } from "../../../types/testCase";
import { ResultValueToLabel } from "../../../views/TestCaseResult/testResultValueToLabel";
import { ShowOnlyOpen } from "../../filter/ShowOnlyOpen";
import { TestCaseType } from "./filter";
import { useCurrentConfigs } from "../../hooks/useCurrentConfigs";
import { usePolicyGroupResourceQuickSearch } from "../../../hooks/qri/usePolicyGroupResourceQuickSearch";
import { CategoryFilter } from "../../filter/CategoryFilter";
import { ResultOptions } from "../../../views/TestCaseResult/TestCaseResult.enums";
import { TestResult } from "./filter/TestResult";

const columnHelper = createQColumnHelper<TestCaseList>();

const testResultVariants: Record<
  TestResultElement["result"],
  "green" | "red" | "gray"
> = {
  passed: "green",
  failed: "red",
  blocked: "gray",
};

export const TestList: React.FC = () => {
  const { isLoading, data } = useTestCaseList();
  const { isLoading: isDeleting, mutate: deleteElement } = useDeleteTestCase();
  const [itemToDelete, setItemToDelete] = useState<TestCaseList | null>(null);
  const policies = useCurrentConfigs();

  const { isLoading: categoryIsLoading, data: categories } =
    usePolicyGroupResourceQuickSearch(isTestCasePolicy);

  const columns = useMemo(() => {
    return [
      columnHelper.code("code", { header: "ID", maxWidth: "100px" }),
      columnHelper.textLink("title", (record) => record.id, {
        header: "Test case",
        weight: 1,
      }),
      columnHelper.text((record) => record.category?.label, {
        header: "Category",
      }),
      columnHelper.date((record) => new Date(record.updated), {
        header: "Last modified",
        maxWidth: "150px",
      }),
      {
        header: "Last results",
        meta: {
          maxWidth: "150px",
        },
        cell: ({
          row: { original: row },
        }: {
          row: { original: TestCaseList };
        }) => {
          if (!row.testResult) {
            return "";
          }

          return (
            <QTag
              variantColor={testResultVariants[row.testResult.result as never]}
            >
              {ResultValueToLabel[row.testResult.result as never]}
            </QTag>
          );
        },
      },
      {
        header: "Issue",
        meta: {
          width: "70px",
        },
        cell: ({
          row: { original: row },
        }: {
          row: { original: TestCaseList };
        }) => {
          const missing = Object.entries(row.policyIssues).filter(
            ([, value]) => !!value,
          );

          if (!missing.length) {
            return "";
          }

          return (
            <QCenter>
              <QIcon
                iconName="FillExclamationTriangleFill"
                color="orange.500"
              />
            </QCenter>
          );
        },
      },
      columnHelper.menu({
        items: (
          <>
            <TableMenuItem onClick={console.log} data-cy="add-test-result">
              Add test result
            </TableMenuItem>
            <TableMenuItem onClick={console.log} data-cy="edit">
              Edit
            </TableMenuItem>
            <TableMenuItem
              onClick={(item: TestCaseList) => setItemToDelete(item)}
              color="red.500"
              data-cy="delete"
            >
              Delete
            </TableMenuItem>
          </>
        ),
        hide: (record) => record.source !== "product-development",
      }),
    ];
  }, []);

  const filterDefinitions = useMemo(() => {
    return {
      type: {
        label: "Test type",
        schema: Filtering.schemas.StringSchema().array().nullish(),
        activeRender: (v: any) => {
          const item = policies?.find((c) => c.type === v);
          if (!item) {
            return;
          }

          return {
            label: "Test type",
            value: item.label,
          };
        },
      },
      category: {
        label: "Category",
        schema: Filtering.schemas.StringSchema().array().nullish(),
        activeRender: (v: any) => {
          const item = categories?.find((c) => c.identifiers.qri === v);
          if (!item) {
            return;
          }

          return {
            label: "Category",
            value: item.displayLabel,
          };
        },
      },
      testResult: {
        label: "Latest results",
        schema: Filtering.schemas.StringSchema().nullish(),
        activeRender: (v: any) => ({
          label: "Result",
          value: v && ResultValueToLabel[v as ResultOptions],
        }),
      },
      show_only_open: {
        label: "Show open issues only",
        schema: Filtering.schemas.BooleanSchema(),
        activeRender: (v: any) => ({
          label: null,
          value: v && "Open issues only",
        }),
      },
    };
  }, [policies, categories]);

  const filterOption = useCallback(
    (
      item: Readonly<TestCaseList>,
      searchTerm: string | undefined,
      filters: Filtering.ResolvedFilters<typeof filterDefinitions> | undefined,
    ) => {
      if (
        searchTerm &&
        !item.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
      ) {
        return false;
      }

      if (filters && Object.values(filters).some((field) => field.value)) {
        const { show_only_open, type, category, testResult } = filters;

        return (
          (!show_only_open?.value ||
            Object.values(item.policyIssues).some((issue) => issue)) &&
          (!type?.value || type.value.includes(item.type)) &&
          (!testResult.value || testResult.value === item.testResult?.result) &&
          (!category?.value || category.value.includes(item.category?.qri))
        );
      }

      return true;
    },
    [],
  ) as DataProvider.FilterOptionFn<TestCaseList>;

  return (
    <>
      <Pagination.Auto pageSizeKey="size" pageIndexKey="page" clientSide>
        <Filtering.FilterProvider
          definitions={filterDefinitions}
          searchTermKey="search"
        >
          <DataProvider.Fixed
            data={data ?? []}
            isLoading={isLoading}
            filterOption={filterOption}
          >
            <QDataTable
              columns={columns}
              data-cy="test-list"
              getRowId={(row) => row.id}
            >
              <Filtering.FormContent>
                <TestCaseType policies={policies} />
                <CategoryFilter
                  categories={categories}
                  isLoading={categoryIsLoading}
                />
                <TestResult />
                <ShowOnlyOpen />
              </Filtering.FormContent>
            </QDataTable>
          </DataProvider.Fixed>
        </Filtering.FilterProvider>
      </Pagination.Auto>
      <Outlet />
      {itemToDelete && (
        <QDeleteConfirmationModal
          title="Delete test"
          onConfirm={() => {
            itemToDelete &&
              deleteElement(itemToDelete as any, {
                onSettled: () => setItemToDelete(null),
              });
          }}
          inProgress={isDeleting}
          onClose={() => setItemToDelete(null)}
          message={
            <QText fontSize="sm">
              Are you sure you want to delete {itemToDelete.codeTitle}? You
              cannot undo this action.
            </QText>
          }
        />
      )}
    </>
  );
};
