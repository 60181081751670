import React, { useMemo, useState, useCallback } from "react";

import {
  isRequirementPolicy,
  RequirementList as RequirementListType,
} from "@design-controls/types";
import {
  createQColumnHelper,
  DataProvider,
  Filtering,
  QDataTable,
  TableMenuItem,
  QDeleteConfirmationModal,
  QText,
  Pagination,
  QIcon,
  QCenter,
} from "@qualio/ui-components";
import { useNavigate } from "react-router";

import { useDeleteRequirement } from "../../../hooks/designElement/useDeleteRequirement";
import { useRequirementList } from "../../../hooks/useRequirementsList";
import { OpenIssue } from "../../../types/openIssue";
import { CategoryFilter } from "../../filter/CategoryFilter";
import { ShowOnlyOpen } from "../../filter/ShowOnlyOpen";
import { ComponentFilter, RequirementType } from "./filter";
import { usePolicyGroupResourceQuickSearch } from "../../../hooks/qri/usePolicyGroupResourceQuickSearch";
import { useResourceLibraryQuickSearch } from "../../hooks/search/useResourceLibraryQuickSearch";
import { useCurrentConfigs } from "../../hooks/useCurrentConfigs";

const columnHelper = createQColumnHelper<RequirementListType>();

export const RequirementList: React.FC = () => {
  const { isLoading, data } = useRequirementList();
  const { isLoading: isDeleting, mutate: deleteElement } =
    useDeleteRequirement();
  const [itemToDelete, setItemToDelete] = useState<RequirementListType | null>(
    null,
  );
  const navigate = useNavigate();

  const { isLoading: categoryIsLoading, data: categories } =
    usePolicyGroupResourceQuickSearch(isRequirementPolicy);

  const requirementPolicy = useCurrentConfigs().filter(isRequirementPolicy);
  const { isLoading: componentIsLoading, data: components } =
    useResourceLibraryQuickSearch(
      requirementPolicy?.[0].component?.[0].resourceIdentifier as string,
    );

  const columns = useMemo(() => {
    return [
      columnHelper.code("code", { header: "ID", maxWidth: "100px" }),
      columnHelper.textLink("title", (record) => record.id, {
        header: "Requirement",
        weight: 1,
      }),
      columnHelper.text((record) => record.component?.label, {
        header: "Component",
      }),
      columnHelper.text((record) => record.category?.label, {
        header: "Category",
      }),
      columnHelper.date((record) => new Date(record.updated), {
        header: "Last modified",
        maxWidth: "150px",
      }),
      {
        header: "Issue",
        meta: {
          width: "70px",
        },
        cell: ({
          row: { original: row },
        }: {
          row: { original: RequirementListType };
        }) => {
          const missing = Object.entries(row.policyIssues).filter(
            ([, value]) => !!value,
          );

          if (!missing.length) {
            return "";
          }

          return (
            <QCenter>
              <QIcon
                iconName="FillExclamationTriangleFill"
                color="orange.500"
              />
            </QCenter>
          );
        },
      },
      columnHelper.menu({
        items: (
          <>
            <TableMenuItem
              onClick={(item: RequirementListType) =>
                navigate(`${item.id}/edit`)
              }
              data-cy="edit"
            >
              Edit
            </TableMenuItem>
            <TableMenuItem
              onClick={(item: RequirementListType) => setItemToDelete(item)}
              color="red.500"
              data-cy="delete"
            >
              Delete
            </TableMenuItem>
          </>
        ),
        hide: (record) => record.source !== "product-development",
      }),
    ];
  }, [navigate]);

  const filterDefinitions = useMemo(() => {
    return {
      type: {
        label: "Requirement type",
        schema: Filtering.schemas.StringSchema().array().nullish(),
        activeRender: (v: any) => {
          const item = requirementPolicy?.find((c) => c.type === v);
          if (!item) {
            return;
          }

          return {
            label: "Requirement type",
            value: item.label,
          };
        },
      },
      component: {
        label: "Component",
        schema: Filtering.schemas.StringSchema().array().nullish(),
        activeRender: (v: any) => {
          const item = components?.results.find((c) => c.identifiers.qri === v);
          if (!item) {
            return;
          }

          return {
            label: "Component",
            value: item.displayLabel,
          };
        },
      },
      category: {
        label: "Category",
        schema: Filtering.schemas.StringSchema().array().nullish(),
        activeRender: (v: any) => {
          const item = categories?.find((c) => c.identifiers.qri === v);
          if (!item) {
            return;
          }

          return {
            label: "Category",
            value: item.displayLabel,
          };
        },
      },
      show_only_open: {
        label: "Show open issues only",
        schema: Filtering.schemas.BooleanSchema().nullish(),
        activeRender: (v: any) => ({
          label: null,
          value: v && "Open issues only",
        }),
      },
    };
  }, [categories, components, requirementPolicy]);

  const filterOption = useCallback(
    (
      item: Readonly<OpenIssue>,
      searchTerm: string | undefined,
      filters: Filtering.ResolvedFilters<typeof filterDefinitions> | undefined,
    ) => {
      if (
        searchTerm &&
        !item.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
      ) {
        return false;
      }

      if (filters && Object.values(filters).some((field) => field.value)) {
        const { show_only_open, type, category, component } = filters;

        return (
          (!show_only_open?.value ||
            Object.values(item.policyIssues).some((issue) => issue)) &&
          (!type?.value || type.value.includes(item.type)) &&
          (!category?.value || category.value.includes(item.category?.qri)) &&
          (!component?.value || component.value.includes(item.component?.qri))
        );
      }

      return true;
    },
    [],
  ) as DataProvider.FilterOptionFn<RequirementListType>;

  return (
    <>
      <Pagination.Auto pageSizeKey="size" pageIndexKey="page" clientSide>
        <Filtering.FilterProvider
          definitions={filterDefinitions}
          searchTermKey="search"
        >
          <DataProvider.Fixed
            data={data ?? []}
            isLoading={isLoading}
            filterOption={filterOption}
          >
            <QDataTable
              columns={columns}
              data-cy="requirement-list"
              getRowId={(row) => row.id}
            >
              <Filtering.FormContent>
                <RequirementType />
                <ComponentFilter
                  isLoading={componentIsLoading}
                  components={components?.results}
                />
                <CategoryFilter
                  categories={categories}
                  isLoading={categoryIsLoading}
                />
                <ShowOnlyOpen />
              </Filtering.FormContent>
            </QDataTable>
          </DataProvider.Fixed>
        </Filtering.FilterProvider>
      </Pagination.Auto>
      {itemToDelete && (
        <QDeleteConfirmationModal
          title="Delete requirement"
          onConfirm={() => {
            itemToDelete &&
              deleteElement(itemToDelete as any, {
                onSettled: () => setItemToDelete(null),
              });
          }}
          inProgress={isDeleting}
          onClose={() => setItemToDelete(null)}
          message={
            <QText fontSize="sm">
              Are you sure you want to delete {itemToDelete.codeTitle}? You
              cannot undo this action.
            </QText>
          }
        />
      )}
    </>
  );
};
